/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Grid, Input } from 'semantic-ui-react';

import { maybeFetchOntologies, actionTypes as entityActionTypes } from 'actions/entities';
import {
  fetchSources,
  actionTypes as sourceActionTypes,
} from 'actions/sources';

import {
  actionTypes,
  fetchBrands,
  fetchGenerations,
  fetchProducts,
  fetchTextsStatus,
  loadMoreReviews,
  onReviewIdsFilterChange,
  onTextFilterChange,
  setBrandFilter,
  setConceptFilter,
  setGenerationFilter,
  setProductFilter,
  setSourceFilter,
} from 'actions/validation';
import { createLoadingSelector } from 'reducers/ui';
import { getCurrentReview, getDropdownItems } from 'reducers/validation';
import { ontologyLabelsItemsSelector } from 'selectors/entities';

import ValidateReview from 'components/admin/validation/ValidateReview';
import { NoMarginGrid } from 'components/ui/Grid';
import { MediumPaddedSegment } from 'components/ui/Segment';
import { ButtonPrimary } from 'components/ui/button';
import { SelectBox, StyledDropdown } from 'components/ui/inputs/Dropdown';
import { PageLayout } from 'components/ui/layout/Page';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const dropDownType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
  })
);

class ValidationPage extends Component {
  static withEnterKeyDown = (onAction, disabled) => (e, data) => {
    if (e.key === 'Enter' && !disabled) {
      onAction(e, data);
    }
  };

  constructor() {
    super();
    this.state = {
      selectedOntology: '',
    };
  }

  componentDidMount() {
    this.props.maybeFetchOntologies();
    this.props.fetchTextsStatus();
    this.props.fetchSources();
    this.props.fetchBrands();
    this.props.loadReviews();
  }

  onSourceSelected = (e, data) => {
    const sourceIds = data.value;
    this.props.setSourceFilter(sourceIds);
  };

  onBrandSelected = (e, data) => {
    const brandId = data.value;
    if (brandId) {
      this.props.fetchProducts(brandId);
    }
    this.props.setBrandFilter(brandId);
  };

  onProductSelected = (e, data) => {
    const productId = data.value;
    if (productId) {
      this.props.fetchGenerations(productId);
    }
    this.props.setProductFilter(productId);
  };

  onGenerationSelected = (e, data) => {
    const generationIds = data.value;
    this.props.setGenerationFilter(generationIds);
  };

  get isLoading() {
    const { loading, currentReview } = this.props;
    return loading || !currentReview;
  }

  resetAndLoadReviews = () => this.props.loadReviews(true);

  renderFilters() {
    const {
      productDropdownOptions,
      brandDropdownOptions,
      sourceDropdownOptions,
      generationDropdownOptions,
      ontologies,
      reviewFilters,
      ontologyLabelsItems,
    } = this.props;

    const { selectedOntology } = this.state;
    // Wait for ontologies to render filters
    if (!ontologies) return null;
    const reviewIdsFilterActionIsDisabled = false;
    const textFilterActionIsDisabled = !!reviewFilters.reviewIds;
    const conceptItems = Object.entries(ontologies[selectedOntology] || {}).map(
      ([conceptId, { name }]) => ({
        key: conceptId,
        value: conceptId,
        text: name,
      })
    );
    return (
      <MediumPaddedSegment style={{ margin: svars.spaceMedium }}>
        <NoMarginGrid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={2} textAlign="right">
              Source
            </Grid.Column>
            <Grid.Column width={4}>
              <StyledDropdown
                placeholder="Choose source"
                fluid
                small="true"
                multiple
                search
                selection
                options={sourceDropdownOptions}
                onChange={this.onSourceSelected}
                value={reviewFilters.sourceIds}
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              Ontology / concept
            </Grid.Column>
            <Grid.Column width={4}>
              <SelectBox
                placeholder="Choose ontology"
                fluid
                search
                selection
                options={ontologyLabelsItems}
                onChange={(e, { value }) =>
                  this.setState({
                    selectedOntology: value,
                  })
                }
                value={selectedOntology}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <SelectBox
                placeholder="Choose concept"
                fluid
                small="true"
                search
                selection
                options={conceptItems}
                onChange={(e, { value }) =>
                  this.props.setConceptFilter(selectedOntology, value)
                }
                value={reviewFilters.conceptId}
                disabled={!selectedOntology}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={2} textAlign="right">
              Product hierarchy
            </Grid.Column>
            <Grid.Column width={4}>
              <StyledDropdown
                placeholder="Choose brand"
                fluid
                small="true"
                search
                selection
                clearable
                disabled={!brandDropdownOptions.length}
                options={brandDropdownOptions}
                onChange={this.onBrandSelected}
                value={reviewFilters.brandId}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <StyledDropdown
                placeholder="Choose product"
                fluid
                small="true"
                search
                selection
                clearable
                disabled={!productDropdownOptions.length}
                options={productDropdownOptions}
                onChange={this.onProductSelected}
                value={reviewFilters.productId}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <StyledDropdown
                placeholder="Choose generation"
                fluid
                small="true"
                search
                selection
                clearable
                disabled={!generationDropdownOptions.length}
                options={generationDropdownOptions}
                onChange={this.onGenerationSelected}
                value={reviewFilters.generationId}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={2} textAlign="right">
              Review ids (comma separated)
            </Grid.Column>
            <Grid.Column width={6}>
              <Input
                onKeyDown={ValidationPage.withEnterKeyDown(
                  this.resetAndLoadReviews,
                  reviewIdsFilterActionIsDisabled
                )}
                action={
                  <ButtonPrimary
                    onClick={this.resetAndLoadReviews}
                    style={{ display: 'inline' }}
                    content="select"
                    disabled={reviewIdsFilterActionIsDisabled}
                  />
                }
                type="text"
                placeholder="5d67e645791e4bb3cd0f9430, 5d67e645791e4bb3cd0f9431, ..."
                value={reviewFilters.reviewIds}
                onChange={this.props.onReviewIdsFilterChange}
                style={{ minWidth: '500px', paddingRight: svars.spaceMedium }}
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              Text
            </Grid.Column>
            <Grid.Column width={6}>
              <Input
                onKeyDown={ValidationPage.withEnterKeyDown(
                  this.resetAndLoadReviews,
                  textFilterActionIsDisabled
                )}
                action={
                  <ButtonPrimary
                    onClick={this.resetAndLoadReviews}
                    style={{ display: 'inline' }}
                    content="search"
                    disabled={textFilterActionIsDisabled}
                  />
                }
                type="text"
                placeholder="moteur, accueil, ..."
                value={reviewFilters.texts}
                onChange={this.props.onTextFilterChange}
                style={{ minWidth: '350px', paddingRight: svars.spaceMedium }}
                disabled={!!reviewFilters.reviewIds}
              />
            </Grid.Column>
          </Grid.Row>
        </NoMarginGrid>
      </MediumPaddedSegment>
    );
  }

  render() {
    const { currentReview, loading } = this.props;
    const validateReviewElement =
      !this.isLoading && currentReview ? (
        <ValidateReview review={currentReview} />
      ) : null;
    return (
      <PageLayout>
        {this.renderFilters()}

        {(
          <MediumPaddedSegment style={{ margin: svars.spaceMedium }}>
            {validateReviewElement}
          </MediumPaddedSegment>
        ) ||
          (!loading && <div>No more review to validate.</div>)}
      </PageLayout>
    );
  }
}

ValidationPage.propTypes = {
  loadReviews: PropTypes.func.isRequired,
  maybeFetchOntologies: PropTypes.func.isRequired,
  fetchSources: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchGenerations: PropTypes.func.isRequired,
  setSourceFilter: PropTypes.func.isRequired,
  setBrandFilter: PropTypes.func.isRequired,
  setProductFilter: PropTypes.func.isRequired,
  setConceptFilter: PropTypes.func.isRequired,
  setGenerationFilter: PropTypes.func.isRequired,
  onTextFilterChange: PropTypes.func.isRequired,
  onReviewIdsFilterChange: PropTypes.func.isRequired,
  fetchTextsStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  // We should be removing currentReview from here and get it from the store in child component
  currentReview: PropTypes.shape({}),
  sourceDropdownOptions: dropDownType.isRequired,
  brandDropdownOptions: dropDownType.isRequired,
  productDropdownOptions: dropDownType.isRequired,
  generationDropdownOptions: dropDownType.isRequired,
  ontologies: commonPropTypes.ontologies,
  ontologyLabelsItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  reviewFilters: PropTypes.shape({
    sourceIds: PropTypes.arrayOf(PropTypes.string),
    brandId: PropTypes.string,
    productId: PropTypes.string,
    generationId: PropTypes.string,
    conceptId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    texts: PropTypes.string,
    reviewIds: PropTypes.string,
  }).isRequired,
};

ValidationPage.defaultProps = {
  currentReview: undefined,
  loading: true,
  ontologies: [],
  ontologyLabelsItems: [],
};

const loadingSelector = createLoadingSelector([
  actionTypes.FETCH_REVIEWS_REQUEST,
  actionTypes.FETCH_ONTOLOGIES_REQUEST,
  entityActionTypes.FETCH_ONTOLOGIES_REQUEST,
  sourceActionTypes.FETCH_SOURCES_REQUEST,
]);

const mapStateToProps = (state) => ({
  ontologies: state.entities.ontologies,
  ontologyLabelsItems: ontologyLabelsItemsSelector(state),
  currentReview: getCurrentReview(state.validation),
  loading: loadingSelector(state),
  sourceDropdownOptions: getDropdownItems(state.validation.sources),
  brandDropdownOptions: getDropdownItems(
    state.validation.productReferential.brands
  ),
  productDropdownOptions: getDropdownItems(
    state.validation.productReferential.products
  ),
  generationDropdownOptions: getDropdownItems(
    state.validation.productReferential.generations
  ),
  productHierarchyOptions: state.validation.productHierarchy,
  reviewFilters: state.validation.reviewFilters,
});

const mapDispatchToProps = {
  loadReviews: loadMoreReviews,
  fetchSources,
  fetchBrands,
  fetchProducts,
  fetchGenerations,
  maybeFetchOntologies,
  fetchTextsStatus,
  setSourceFilter,
  setBrandFilter,
  setProductFilter,
  setGenerationFilter,
  setConceptFilter,
  onTextFilterChange,
  onReviewIdsFilterChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationPage);
